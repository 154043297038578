/* eslint-disable sonarjs/cognitive-complexity */
import { nanoid } from '@reduxjs/toolkit';
import { InventoryProductTitles, OrderType } from 'global-constants';
import {
  SKIP_SHOWING_LINK_FAKE_TRACKING_ID,
  SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID,
} from './constants';
import { FlattenedOrderItem } from './types';
import { NewOrderItem } from 'types';
import { getItemProductTitle } from 'pages/Orders/utils';

const getOrderItemBoundDirection = (
  isRetrieval: boolean,
  hasInboundOrderItemId: boolean
) => {
  const orderItemBoundDirection = {
    isInboundOrderItem: false,
    isOutboundOrderItem: false,
  };

  if (!isRetrieval) {
    return orderItemBoundDirection;
  }

  if (hasInboundOrderItemId) {
    orderItemBoundDirection.isOutboundOrderItem = true;
  } else {
    orderItemBoundDirection.isInboundOrderItem = true;
  }

  return orderItemBoundDirection;
};

export const getInboundOrderItemAssetDetails = (
  orderItems: any,
  isRetrieval: boolean
): Record<string, any> => {
  if (!isRetrieval) {
    return {};
  }

  return orderItems.reduce((acc: Record<string, any>, orderItem: any) => {
    const { make, model } = orderItem?.asset ?? {};
    const orderItemName =
      make || model
        ? `${make ?? ''}${make && model ? ' ' : ''}${model ?? ''}`
        : 'Inbound retrieval kit';
    const inboundOrderItemId = orderItem?.inboundOrderItemId ?? '';

    if (!inboundOrderItemId) {
      acc[orderItem.id] = {};
      acc[orderItem.id].itemName = orderItemName;
      acc[orderItem.id].assetId = orderItem?.asset?.id ?? '';
      acc[orderItem.id].assetNumber = orderItem?.asset?.assetNumber ?? '';
    }
    return acc;
  }, {});
};

export const getFlattenedOrderItems = (
  orderItem: any,
  orderTypeName: string,
  options: { isRetrieval?: boolean; isProcurement?: boolean } = {
    isRetrieval: false,
    isProcurement: false,
  }
): FlattenedOrderItem => {
  const orderItemDisplayName = getItemProductTitle(orderItem, orderTypeName);
  const trackingLinkUrl = orderItem?.tracking?.trackingLink ?? '';
  const trackingNumber = orderItem?.tracking?.trackingNumber ?? '';
  const assetNumber = orderItem?.asset?.assetNumber;
  const assetImageUrl = orderItem?.assetImages?.[0]?.image?.url;
  const productImageUrl =
    orderItem?.productVariant?.productImages?.[0]?.image?.url;
  const imageUrl = assetImageUrl || productImageUrl || undefined;
  const hasInboundOrderItemId = Boolean(orderItem?.inboundOrderItemId);

  const flatOrderItemDetail = {
    id: orderItem?.id ?? '',
    trackingId: orderItem?.tracking?.id ?? '',
    assetId: orderItem?.asset?.id ?? '',
    orderTypeName,
    shipmentStatus: orderItem?.shipmentStatus ?? 'PENDING',
    orderItemDisplayName,
    ...getOrderItemBoundDirection(
      options?.isRetrieval ?? false,
      hasInboundOrderItemId
    ),
  };

  return {
    ...flatOrderItemDetail,
    ...(typeof trackingLinkUrl === 'string' ? { trackingLinkUrl } : {}),
    ...(typeof trackingNumber === 'string' ? { trackingNumber } : {}),
    ...(typeof assetNumber === 'string' ? { assetNumber } : {}),
    ...(typeof imageUrl === 'string' ? { imageUrl } : {}),
  };
};

const getOrderItemFieldInfo = (
  checkingField: string,
  hiddenLinkFakeIdPrefix: string
) => {
  return (orderItem: any) => {
    if (orderItem[checkingField]?.id) return orderItem[checkingField];
    return {
      id: `${hiddenLinkFakeIdPrefix}-${nanoid(30)}`,
    };
  };
};

const getTrackingInfo = getOrderItemFieldInfo(
  'tracking',
  SKIP_SHOWING_LINK_FAKE_TRACKING_ID
);
const getAssetInfo = getOrderItemFieldInfo(
  'asset',
  SKIP_SHOWING_DETAILS_LINK_FAKE_ASSET_ID
);

export const chunkOrderItemsByTrackingId = (
  orderItems: any[],
  inboundOrderItemAssetDetailsTable: Record<string, any>
) => {
  const hasInboundOrderItemNames =
    Object.keys(inboundOrderItemAssetDetailsTable).length > 0;
  const orderItemsWithInboundOrderItemNames = orderItems.map(
    (orderItem: any) => {
      const hasOrderItem = !!inboundOrderItemAssetDetailsTable[orderItem.id];
      const hasAsset = Boolean(orderItem?.asset);

      if (hasInboundOrderItemNames && hasOrderItem && hasAsset) {
        const inboundOrderItemName =
          inboundOrderItemAssetDetailsTable[orderItem.id]?.itemName;
        const asset = { ...orderItem.asset };

        asset.id = inboundOrderItemAssetDetailsTable[orderItem.id]?.assetId;
        asset.assetNumber =
          inboundOrderItemAssetDetailsTable[orderItem.id]?.assetNumber;

        orderItem = { ...orderItem, asset };

        return {
          ...orderItem,
          ...(inboundOrderItemName ? { inboundOrderItemName } : {}),
        };
      }

      return orderItem;
    }
  );
  const orderItemsWithTrackingInfo = orderItemsWithInboundOrderItemNames.map(
    (item) => ({
      ...item,
      tracking: getTrackingInfo(item),
    })
  );

  const orderItemsWithAssetInfo = orderItemsWithTrackingInfo.map((item) => ({
    ...item,
    asset: getAssetInfo(item),
  }));

  const chunkedByTrackingIdOrderItems = orderItemsWithAssetInfo.reduce(
    (acc: Record<string, any>, nextOrderItem: any) => {
      if (nextOrderItem.tracking.id in acc) {
        acc[nextOrderItem.tracking.id].push(nextOrderItem);
      } else {
        acc[nextOrderItem.tracking.id] = [nextOrderItem];
      }
      return acc;
    },
    {}
  );

  return Object.keys(chunkedByTrackingIdOrderItems).map(
    (trackingId: string) => [
      trackingId,
      chunkedByTrackingIdOrderItems[trackingId],
    ]
  );
};
