const themeColors = {
  alabasterN: '#F8F8F8',
  black: '#000000',
  offBlack: '#212121',
  strongBlue: '#1976D2',
  blackPearl: '#152023',
  jellyBeanBlue: '#517A85',
  curiousBlue: '#3687D8',
  limedSpruce: '#2E444A',
  boulderWN: '#797474',
  brightGray: '#EFEFEF',
  burntSiennaR: '#F27058',
  grayWN: '#828282',
  jungleG: '#122023',
  maizeYO: '#F9C54D',
  backgroundYO: '#FDF4E0',
  mineShaftWN: '#333333',
  mediumGreen: '#53853C',
  offWhite: '#FFFFFD',
  onyxWN: '#393636',
  silverWN: '#A6A5A5',
  white: '#FFFFFF',
  ghostWhite: '#F6FAFD',
  wildSandWN: '#F4F4F4',
  heartOfIce: '#F4FCFF',
  butterBlond: '#F1C764',
  chiGong: '#D32F2F',
  lushParadise: '#2E7D32',
  mercury: '#EBEBEB',
  rockBlue: '#9BC6CE',
  grayBD: '#BDBDBD',
  gray60: '#60605D',
  gray70: '#707070',
  gray75: '#757575',
  gray80: '#808080',
  gray94: '#949394',
  grayD5: '#D5D6D5',
  grayD9: '#D9D9D9',
  grayEF: '#EFEFEF',
  grayF6: '#F6F6F6',
  grayF7: '#F7FAFB',
  grayE0: '#E0E0E0',
  grayFB: '#FBFBFB',
  grayB7: '#B7B7B7',
  grayFA: '#FAFAFA',
  notificationBG: '#323232',
  mustardY: '#EDA600',
  solidSilver: '#C0C0C0',
  charlestonGreen: '#282928',
  goldFusion: '#7A8450',
  antiqueW: '#F8EED3',
  merino: '#FAF7F0',
  jaffa: '#F38A34',
  pelorous: '#4DB7C6',
  woodSmoke: '#131414',
  transparentGrey: 'rgba(19, 20, 20, 0.12)',
  disableInputGrey: 'rgba(19, 20, 20, 0.26)',
  snow: '#F7F9FC',
};

export default themeColors;
