/* eslint-disable sonarjs/cognitive-complexity */
import React from 'react';
import { NetworkStatus, useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  GridPaginationModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';
import { DEFAULT_PAGINATION_MODEL } from 'global-constants';
import DownloadIcon from 'components/Icons/DownloadIcon';
import { Asset } from 'types';
import TextIconButton from 'components/TextIconButton';
import { GlobalModals } from 'store/constants';
import { modalSlice, notificationSlice } from 'store/slices';
import { GlobalState } from 'store/types';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import useGetGlobalPurchaser from 'hooks/useGetGlobalPurchaser';
import useDebouncedQuery from 'hooks/useDebouncedSearch';
import AssetActions from './components/AssetActions';
import DeviceFilters from './components/DeviceFilters';
import DeviceAssignedConfirmationModal from './components/DeviceAssignedConfirmationModal';
import EditDeviceModal from './components/EditDeviceModal';
import DeleteDeviceModal from './components/DeleteDeviceModal';
import ProcessDeviceModal from './components/ProcessDeviceModal';
import RecycleDeviceModal from './components/RecycleDeviceModal';
import RepairDeviceModal from './components/RepairDeviceModal';
import ResellDeviceModal from './components/ResellDeviceModal';
import UnlockDeviceModal from './components/unlock_device/UnlockDeviceModal';
import { getAssetCSVHeaders } from 'pages/Assets/utils';
import { downloadDataToCSV } from 'services/csv';
import { getTodaysDate } from 'services/date';
import { useGetDeviceActionsEnablement } from './hooks/useGetDeviceActionsEnablement';
import { flattenDevicesData } from './utils';
import * as DevicesDatagridStyles from './styles';
import { GET_ASSETS } from './queries';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import AddDeviceModal from './components/AddDeviceModal';
import { capitalize } from 'services';
import AssetCSVDownloadButton from 'pages/Assets/csv/AssetsCSVDownloadButton';
import { AssetImagesModal } from 'components';
import { NotificationMessages } from 'components/GlobalToastNotification/constants';
import UnlockOrderSubmittedModal from './components/unlock_device/UnlockOrderSubmittedModal';
import * as Sentry from '@sentry/react';

import AssetsColumnSelect from '../../columns/AssetsColumnSelect';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import AssetComplexFilterBank from 'pages/Assets/filters/AssetComplexFilterBank';
import AssetComplexFilterDrawer from 'pages/Assets/filters/AssetComplexFilterDrawer';
import SearchComboBox from 'pages/Assets/components/DevicesDatagrid/components/SearchComboBox';
import AssetsDatagrid from 'pages/Assets/components/DevicesDatagrid/components/AssetsDatagrid';
import UploadPopoverButton from 'components/uploads/UploadPopover';
import AssetUploadPopover from 'pages/Assets/csv/AssetUploadPopover';
import useOrderOrAssetFilterSync from 'hooks/useOrderOrAssetFilterSync';

const DevicesDatagrid = () => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const styles = DevicesDatagridStyles;

  const {
    OnFlagTrueWrapper: ShowIfComplexFilterEnabled,
    OnFlagFalseWrapper: HideIfComplexFilterDisabled,
  } = useFeatureFlagService(FeatureFlagNames.COMPLEX_CLIENT_DEVICES_FILTERING, {
    debugFlags: true,
  });

  const { isFlagOn: isAssetUploadToolEnabled } = useFeatureFlagService(
    FeatureFlagNames.ASSET_UPLOAD_TOOL,
    {
      debugFlags: true,
    }
  );

  //Pagination -- see Employees Datagrid for more details
  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>(DEFAULT_PAGINATION_MODEL);

  // Row Selection
  const [rowSelectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() => []);
  const [selectedDevice, setSelectedDevice] = React.useState<Asset | undefined>(
    undefined
  );

  // Filtering
  const [assetTypeName, setAssetTypeName] = React.useState<string>('');
  const [assetMake, setAssetMake] = React.useState<string>('');
  const [assetModel, setAssetModel] = React.useState<string>('');
  const [assetStatus, setAssetStatus] = React.useState<string>('');
  const [assetPhysicalCondition, setAssetPhysicalCondition] =
    React.useState<string>('');
  const [assetDepotName, setAssetDepotName] = React.useState<string>('');
  const [assetDisplaySize, setAssetDisplaySize] = React.useState<string>('');
  const [assetMemory, setAssetMemory] = React.useState<string>('');
  const [assetStorage, setAssetStorage] = React.useState<string>('');
  const [assetProcessor, setAssetProcessor] = React.useState<string>('');
  const [allAssetLoadingError, setAllAssetLoadingError] =
    React.useState<boolean>(false);
  const [isAllAssetsLoading, setIsAllAssetsLoading] =
    React.useState<boolean>(false);

  const refetchSearchAssets = async () => {
    await client.refetchQueries({
      include: [GET_ASSETS],
    });
  };

  // Asset Actions CTA Enablement
  const deviceActionsEnablement = useGetDeviceActionsEnablement();
  const {
    enableProcessDeviceAction,
    enableRepairDeviceAction,
    enableRecycleDeviceAction,
    enableResellDeviceAction,
    enableAllocateDeviceAction,
    enableEditDeviceAction,
    enableDeleteDeviceAction,
    enableUnlockDeviceAction,
    setEnableUnlockDeviceAction,
  } = deviceActionsEnablement;

  useOrderOrAssetFilterSync('assets');

  const resetPageState = (
    paginationModel: Partial<GridPaginationModel> | undefined = undefined
  ) => {
    const page = paginationModel?.page ?? DEFAULT_PAGINATION_MODEL.page;
    const pageSize =
      paginationModel?.pageSize ?? DEFAULT_PAGINATION_MODEL.pageSize;
    setPaginationModel((prevModel) => ({
      ...prevModel,
      page,
      pageSize,
    }));
  };

  //Organization & Purchaser Info
  const userOrganization = useGetUserOrganization();
  const globalPurchaser = useGetGlobalPurchaser();
  const organizationId = userOrganization?.id || '';
  const purchaserId = globalPurchaser?.id || '';

  //Modal state
  const currentModal = useSelector<GlobalState>(
    (state) => state.modal.currentModal
  );
  const showAddDeviceModal = currentModal === GlobalModals.ADD_DEVICE;
  const showEditDeviceModal = currentModal === GlobalModals.EDIT_DEVICE;
  const showProcessDeviceModal = currentModal === GlobalModals.PROCESS_DEVICE;
  const showRecycleDeviceModal = currentModal === GlobalModals.RECYCLE_DEVICE;
  const showRepairDeviceModal = currentModal === GlobalModals.REPAIR_DEVICE;
  const showUnlockDeviceModal = currentModal === GlobalModals.UNLOCK_DEVICE;
  const showResellDeviceModal = currentModal === GlobalModals.RESELL_DEVICE;
  const showDeleteDeviceModal = currentModal === GlobalModals.DELETE_DEVICE;
  const showAssignedDeviceConfirmationModal =
    currentModal === GlobalModals.DEVICE_ASSIGNED_CONFIRMATION;
  const showAssetImagesModal = currentModal === GlobalModals.ASSET_IMAGES;

  const handleModalOnClose = (unsetSelectedDevice: boolean = false) => {
    if (unsetSelectedDevice) {
      setSelectedDevice(undefined);
      setSelectionModel([]);
      deviceActionsEnablement.resetState();
    }
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.NONE));
  };

  const [
    fetchAllAssets,
    {
      data: orgAssetData,
      networkStatus,
      fetchMore,
      error: fetchAllAssetsError,
    },
  ] = useSafeLazyQuery(GET_ASSETS, {
    onCompleted(data) {
      const assetData = data.searchAssets;
      const { assets, count } = assetData;

      if (assets.length < count) {
        return;
      }
      setIsAllAssetsLoading(false);
      const flattenedAssetData = assets.map(flattenDevicesData);
      const transformedAssetData = flattenedAssetData.map((asset: any) => {
        const {
          assetNumber,
          type,
          make,
          model,
          status,
          deviceAssessment,
          assignee,
          assigneeEmail,
          cosmeticCondition,
          serialNumber,
          color,
          displaySize,
          memory,
          storage,
          processor,
          keyboard,
          depotLocation,
          purchaseDateDisplay,
          warrantyExpirationDisplay,
          lastOrderItemNumber,
          dateOfLastCheckIn,
          dateOfLastCheckOut,
          ...rest
        } = asset;

        return {
          assetNumber,
          type,
          make,
          model,
          status: capitalize(status.replace('MONO_', '').replace('_', ' ')),
          deviceAssessment: deviceAssessment || null,
          assignee,
          assigneeEmail,
          cosmeticCondition,
          serialNumber,
          color,
          displaySize,
          memory,
          storage,
          processor,
          keyboard,
          depotLocation,
          purchaseDateDisplay,
          warrantyExpirationDisplay,
          lastOrderItemNumber,
          dateOfLastCheckIn,
          dateOfLastCheckOut,
        };
      });

      const todaysDate = getTodaysDate('MMM_DD_yyyy');
      const assetDataCSVFilename = `${userOrganization?.name}_Assets_${todaysDate}`;

      const assetCSVHeaders = getAssetCSVHeaders(
        process.env.REACT_APP_OPERATIONS_BACKEND
      );

      downloadDataToCSV(
        transformedAssetData,
        assetCSVHeaders,
        assetDataCSVFilename
      );
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
  });

  // delay 2 seconds before fetching more assets to avoid 502 errors from bff (this is minimal viable delay for development)
  const allOrgAssetDataLength = useDebouncedQuery(
    `${orgAssetData?.searchAssets?.assets?.length}`,
    2300
  );

  const handleDownloadAllDataClick = () => {
    setAllAssetLoadingError(false);
    setIsAllAssetsLoading(true);
    fetchAllAssets({
      variables: {
        organizationId: userOrganization?.id,
        limit: 100,
        offset: '0',
      },
    });
  };

  React.useEffect(() => {
    if (fetchAllAssetsError) {
      setAllAssetLoadingError(true);
      setIsAllAssetsLoading(false);
    }
    if (!orgAssetData) return;
    const assetData = orgAssetData.searchAssets;
    const { assets, count } = assetData;
    const fetchMoreAssets = () => {
      if (
        !allAssetLoadingError &&
        assets.length < count &&
        networkStatus === NetworkStatus.ready
      ) {
        fetchMore({
          variables: {
            offset: `${allOrgAssetDataLength}`,
          },
          updateQuery: (prev, { fetchMoreResult }) => ({
            searchAssets: {
              count,
              assets: [
                ...prev.searchAssets.assets,
                ...fetchMoreResult.searchAssets.assets,
              ],
            },
          }),
        }).catch((error) => {
          setAllAssetLoadingError(true);
          setIsAllAssetsLoading(false);
          dispatch(
            notificationSlice.actions.setNotice({
              showNotice: true,
              noticeContent: NotificationMessages.GENERIC_SOMETHING_WENT_WRONG,
            })
          );
        });
      }
    };
    fetchMoreAssets();
  }, [allAssetLoadingError, allOrgAssetDataLength, fetchAllAssetsError]);

  React.useEffect(() => {
    resetPageState();
  }, [
    assetTypeName,
    assetMake,
    assetModel,
    assetStatus,
    assetPhysicalCondition,
    assetDepotName,
  ]);

  const [showUnlockOrderSubmittedModal, setShowUnlockOrderSubmittedModal] =
    React.useState<boolean>(false);

  return (
    <>
      <Container sx={styles.ContentDivSx}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Stack direction="column" sx={styles.MainStackSx}>
            <Typography variant="h4" className="devices-view-title">
              Devices
            </Typography>
            <Typography className="devices-view-subtitle">
              Please select a device to perform an action.
            </Typography>
          </Stack>
          <AssetActions
            context="table"
            device={selectedDevice}
            enableProcessDeviceAction={enableProcessDeviceAction}
            enableRepairDeviceAction={enableRepairDeviceAction}
            enableUnlockDeviceAction={enableUnlockDeviceAction}
            enableRecycleDeviceAction={enableRecycleDeviceAction}
            enableResellDeviceAction={enableResellDeviceAction}
            enableAllocateDeviceAction={enableAllocateDeviceAction}
            enableEditDeviceAction={enableEditDeviceAction}
            enableDeleteDeviceAction={enableDeleteDeviceAction}
          />
        </Stack>
        <Stack direction="column" sx={styles.InnerStackSx}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <SearchComboBox
              resetPageState={resetPageState}
              organizationId={organizationId}
            />
            <Stack flexDirection="row" justifyContent="space-between">
              <AssetCSVDownloadButton />
              {isAssetUploadToolEnabled() && <AssetUploadPopover />}
              <AssetsColumnSelect />
              <ShowIfComplexFilterEnabled>
                <AssetComplexFilterDrawer />
              </ShowIfComplexFilterEnabled>
            </Stack>
          </Stack>
          <HideIfComplexFilterDisabled>
            <DeviceFilters
              setAssetTypeName={setAssetTypeName}
              setAssetMake={setAssetMake}
              setAssetModel={setAssetModel}
              setAssetStatus={setAssetStatus}
              setAssetPhysicalCondition={setAssetPhysicalCondition}
              setAssetDepotName={setAssetDepotName}
              setAssetDisplaySize={setAssetDisplaySize}
              setAssetMemory={setAssetMemory}
              setAssetStorage={setAssetStorage}
              setAssetProcessor={setAssetProcessor}
            />
          </HideIfComplexFilterDisabled>
          <ShowIfComplexFilterEnabled>
            <AssetComplexFilterBank />
          </ShowIfComplexFilterEnabled>
        </Stack>
        <Box sx={styles.DataGridBoxSx}>
          <AssetsDatagrid
            isAllAssetsLoading={isAllAssetsLoading}
            organizationId={organizationId}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            assetTypeName={assetTypeName}
            assetMake={assetMake}
            assetModel={assetModel}
            assetStatus={assetStatus}
            assetPhysicalCondition={assetPhysicalCondition}
            assetDepotName={assetDepotName}
            assetDisplaySize={assetDisplaySize}
            assetMemory={assetMemory}
            assetProcessor={assetProcessor}
            assetStorage={assetStorage}
            selectedDevice={selectedDevice}
            setSelectedDevice={setSelectedDevice}
            rowSelectionModel={rowSelectionModel}
            setSelectionModel={setSelectionModel}
            deviceActionsEnablement={deviceActionsEnablement}
            setEnableUnlockDeviceAction={setEnableUnlockDeviceAction}
          />
        </Box>
      </Container>
      <AddDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        open={showAddDeviceModal}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchSearchAssets}
      />
      <EditDeviceModal
        device={selectedDevice}
        open={showEditDeviceModal}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchSearchAssets}
        enableActionsByDevice={deviceActionsEnablement.enableActionsByDevice}
      />
      <ProcessDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={selectedDevice}
        open={showProcessDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchSearchAssets}
      />
      <RecycleDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={selectedDevice}
        open={showRecycleDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchSearchAssets}
      />
      <RepairDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={selectedDevice}
        open={showRepairDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchSearchAssets}
      />
      <UnlockDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={selectedDevice}
        open={showUnlockDeviceModal}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchSearchAssets}
        setShowUnlockOrderSubmittedModal={setShowUnlockOrderSubmittedModal}
        enableActionsByDevice={deviceActionsEnablement.enableActionsByDevice}
      />
      <UnlockOrderSubmittedModal
        open={showUnlockOrderSubmittedModal}
        setShowUnlockOrderSubmittedModal={setShowUnlockOrderSubmittedModal}
      />
      <ResellDeviceModal
        purchaserId={purchaserId}
        organizationId={organizationId}
        device={selectedDevice}
        open={showResellDeviceModal}
        handleClose={handleModalOnClose}
        deviceActionsEnablement={deviceActionsEnablement}
        refetchSearchAssets={refetchSearchAssets}
      />
      <DeviceAssignedConfirmationModal
        open={showAssignedDeviceConfirmationModal}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchSearchAssets}
      />
      <DeleteDeviceModal
        open={showDeleteDeviceModal}
        assetId={selectedDevice?.id ?? undefined}
        handleClose={handleModalOnClose}
        refetchSearchAssets={refetchSearchAssets}
      />
      <AssetImagesModal
        asset={selectedDevice}
        open={showAssetImagesModal}
        handleClose={handleModalOnClose}
      />
    </>
  );
};

export default DevicesDatagrid;
