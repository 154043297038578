/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridCallbackDetails,
  GridCellParams,
  GridPaginationModel,
  GridRowSelectionModel,
  useGridApiRef,
  GridSortModel,
} from '@mui/x-data-grid-pro';
import DataGrid from 'components/DataGrid';
import {
  GET_ASSETS,
  GET_COMPLEX_ASSET_SEARCH,
} from 'pages/Assets/components/DevicesDatagrid/queries';
import { useGetDevicesColumns } from 'pages/Assets/components/DevicesDatagrid/hooks/useGetDevicesColumns';
import { useSafeQuery } from 'hooks/useSafeQuery';
import { FeatureFlagNames } from 'enums';
import useFeatureFlagService from 'hooks/useFeatureFlagService';
import {
  selectSearchedQueryVariable,
  selectComplexSearch,
} from 'store/slices/assets/comboBoxFilter/selectors';
import { getSortParams } from 'pages/Assets/components/DevicesDatagrid/utils';
import { GlobalModals } from 'store/constants';
import {
  PaginationChangeReason,
  ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
} from 'components/DataGrid/constants';
import {
  SortDirection,
  GRID_SORT_FIELD_TO_SERVER_TABLE,
  GENERIC_PAGE_SIZE_OPTIONS,
} from 'global-constants';
import { ASSET_CONDITION_OPTIONS_LOOKUP } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/components/AssetConditionFilter/constants';
import { ASSET_STATUS_OPTIONS_LOOKUP } from 'pages/Assets/components/DevicesDatagrid/components/DeviceFilters/components/AssetStatusFilter/constants';
import { modalSlice } from 'store/slices';
import { Asset, SortOrder } from 'types';
import { assetFilterActions } from 'store/slices/assets/filter';
import {
  selectAllChosenEntries,
  selectComplexFilterEntryDTO,
} from 'store/shared/selectors';
import AssetsDatagridProps from './types';
import {
  useColumnVisibilityModel,
  useResetDeviceEnablementOnColumnEmpty,
  useHandleColumnHeaderDragAndDrop,
} from 'pages/Assets/components/DevicesDatagrid/hooks';

const AssetsDatagrid = (props: AssetsDatagridProps) => {
  const {
    isAllAssetsLoading,
    organizationId,
    paginationModel,
    assetTypeName,
    assetMake,
    assetModel,
    assetStatus,
    assetPhysicalCondition,
    assetDepotName,
    assetDisplaySize,
    assetMemory,
    assetProcessor,
    assetStorage,
    selectedDevice,
    setSelectedDevice,
    rowSelectionModel,
    setPaginationModel,
    setSelectionModel,
    deviceActionsEnablement,
    setEnableUnlockDeviceAction,
  } = props;

  const { isFlagOn: isComplexFilterEnabled } = useFeatureFlagService(
    FeatureFlagNames.COMPLEX_CLIENT_DEVICES_FILTERING,
    {},
  );

  const apiRef = useGridApiRef();
  const [rowHoveringModel, setRowHoveringModel] = React.useState(() => '');
  const [devicesSortModel, setDevicesSortModel] =
    React.useState<SortOrder | null>(null);

  const dispatch = useDispatch();
  const searchedQueryVariable = useSelector(selectSearchedQueryVariable);
  const comboSearchComplexEntry = useSelector(selectComplexSearch);
  const selectedFilterEntries = useSelector(selectAllChosenEntries('assets'));
  const assetSearchEntries = useSelector(selectComplexFilterEntryDTO('assets'));

  const getColumnVisibilityModel = useColumnVisibilityModel();
  useResetDeviceEnablementOnColumnEmpty(
    deviceActionsEnablement,
    setSelectionModel,
  );
  const columnVisibilityModel = getColumnVisibilityModel();

  useHandleColumnHeaderDragAndDrop(apiRef);

  React.useEffect(() => {
    dispatch(
      assetFilterActions.setComplexFilterEntriesReducer(selectedFilterEntries),
    );
  }, [selectedFilterEntries.length]);

  const handleOnRowOver = (event: any) => {
    const rowId = event.currentTarget.getAttribute('data-id');
    setRowHoveringModel(rowId);
  };

  const handleOnRowLeave = () => {
    setRowHoveringModel('');
  };

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    const isRowBeingUnSelected = ids.length === 0;
    if (isRowBeingUnSelected) {
      setSelectionModel([]);
      setSelectedDevice(undefined);
      deviceActionsEnablement.resetState();
    } else {
      const selectedDeviceId = ids[ids.length - 1];
      const selectedDevice = devices.filter(
        // Using double equals because Asset id type is string but is number from codebase
        // Changing the type to be correct is breaking other parts of the codebase
        // TODO: Create Asset id type to be number
        //eslint-disable-next-line eqeqeq
        (device: Asset) => String(device.id) == String(selectedDeviceId),
      )[0];
      setSelectionModel([selectedDeviceId]);
      setSelectedDevice(selectedDevice);
      deviceActionsEnablement.enableActionsByDevice(selectedDevice);
    }
  };

  const oldQueryVariables = {
    organizationId,
    limit: paginationModel.pageSize,
    offset: `${paginationModel.page * paginationModel.pageSize}`,
    ...(assetTypeName && { assetTypeName }),
    ...(assetMake && { make: assetMake }),
    ...(assetModel && { model: assetModel }),
    // eslint-disable-next-line security/detect-object-injection
    ...(assetStatus && {
      status: ASSET_STATUS_OPTIONS_LOOKUP[assetStatus],
    }),
    ...(assetPhysicalCondition && {
      // eslint-disable-next-line security/detect-object-injection
      cosmeticCondition: ASSET_CONDITION_OPTIONS_LOOKUP[assetPhysicalCondition],
    }),
    ...(assetDepotName && { depotName: assetDepotName }),
    ...searchedQueryVariable,
    ...(assetDisplaySize && { displaySize: assetDisplaySize }),
    ...(assetMemory && { memory: assetMemory }),
    ...(assetProcessor && { processor: assetProcessor }),
    ...(assetStorage && { storage: assetStorage }),
    ...(devicesSortModel
      ? getSortParams(devicesSortModel)
      : { orderDesc: 'createdAt' }),
  };

  const newQueryVariables = {
    organizationId,
    limit: paginationModel.pageSize,
    offset: paginationModel.page * paginationModel.pageSize,
    searchEntries: comboSearchComplexEntry ?? assetSearchEntries,
    ...searchedQueryVariable,
    ...(devicesSortModel
      ? getSortParams(devicesSortModel)
      : { orderDesc: 'createdAt' }),
  };

  const variables = isComplexFilterEnabled()
    ? newQueryVariables
    : oldQueryVariables;

  const queryToRun = isComplexFilterEnabled()
    ? GET_COMPLEX_ASSET_SEARCH
    : GET_ASSETS;

  const { data, loading } = useSafeQuery(queryToRun, {
    variables,
    fetchPolicy: 'network-only',
    onCompleted(data) {
      const assets = (data?.searchAssets?.assets || []) as Asset[];

      if (selectedDevice) {
        assets.forEach((asset: Asset) => {
          if (asset.id === selectedDevice.id) {
            setSelectedDevice(asset);
          }
        });
      }
    },
  });

  const devices = isComplexFilterEnabled()
    ? data?.getComplexAssetSearch?.assets ?? []
    : data?.searchAssets?.assets ?? [];

  const rowCount = isComplexFilterEnabled()
    ? data?.getComplexAssetSearch?.count ?? 0
    : data?.searchAssets?.count ?? 0;

  const setAssetImagesModal = () => {
    dispatch(modalSlice.actions.setCurrentModal(GlobalModals.ASSET_IMAGES));
  };

  const handleOnPaginationModelChange = (
    model: GridPaginationModel,
    details: GridCallbackDetails,
  ) => {
    if (details?.reason === PaginationChangeReason.SET_PAGINATION_MODEL) {
      setPaginationModel(model);
    }
  };

  const handleSortModelChange = (
    sortModel: GridSortModel,
    _callbackDetails: GridCallbackDetails,
  ): void => {
    if (!sortModel.length) {
      setDevicesSortModel(null);
      return;
    }
    const { field, sort: sortDirection } = sortModel[0];

    const serverSortDirection = GRID_SORT_FIELD_TO_SERVER_TABLE[
      sortDirection as keyof typeof GRID_SORT_FIELD_TO_SERVER_TABLE
    ] as SortDirection;

    setDevicesSortModel({
      direction: serverSortDirection,
      field,
    });
  };

  const handleOnCellClick = (params: GridCellParams) => {
    if (params.id === rowSelectionModel[0]) {
      onRowSelectionModelChange([]);
    } else {
      onRowSelectionModelChange([params.id]);
    }
  };

  const columns = useGetDevicesColumns(
    rowSelectionModel,
    rowHoveringModel,
    setAssetImagesModal,
  );

  React.useEffect(() => {
    if (loading) {
      setEnableUnlockDeviceAction(false);
    }
  }, [loading]);

  const assetTableIsLoading = loading || isAllAssetsLoading;

  return (
    <DataGrid
      apiRef={apiRef}
      rows={devices}
      rowCount={rowCount}
      columns={columns}
      autoHeight={false}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      disableColumnSelector
      initialState={{
        columns: {
          // @ts-ignore
          columnVisibilityModel,
        },
      }}
      // @ts-ignore
      columnVisibilityModel={columnVisibilityModel}
      checkboxSelection
      onPaginationModelChange={handleOnPaginationModelChange}
      sortingMode="server"
      onSortModelChange={handleSortModelChange}
      rowSelectionModel={rowSelectionModel}
      onCellClick={handleOnCellClick}
      loading={assetTableIsLoading}
      pageSizeOptions={GENERIC_PAGE_SIZE_OPTIONS}
      disableMultipleRowSelection
      disableMultipleColumnsSorting
      hideFooterSelectedRowCount
      keepNonExistentRowsSelected
      slotProps={{
        panel: {
          placement: 'bottom-end',
        },
        filterPanel: {
          sx: { visibility: 'hidden' },
        },
        row: {
          onMouseOver: handleOnRowOver,
          onMouseLeave: handleOnRowLeave,
        },
      }}
      classes={{
        virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
      }}
    />
  );
};

export default AssetsDatagrid;
