/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import FilterToggleButton from 'components/complex_filter/FilterToggleButton';
import FilterDrawerHeader from 'components/complex_filter/FilterDrawerHeader';
import FilterDrawerContent from 'components/complex_filter/FilterDrawerContent';
import FilterDrawerFooter from 'components/complex_filter/FilterDrawerFooter';
import ComplexFilterDrawerProps from './types';
import * as ComplexFilterDrawerStyles from './styles';

const ComplexFilterDrawer = (props: ComplexFilterDrawerProps) => {
  const refEl = React.useRef();
  const styles = ComplexFilterDrawerStyles;

  const {
    outerBoxOverrideSx = {},
    filterButtonOverrideSx = {},
    selectFilterBadgeSx = {},
    filterSubTree,
    selectFilterBadgeTestId,
  } = props;

  const popoverOpen = useSelector(props.selectPopoverOpen(filterSubTree));

  // SxProps/styles
  const OuterBoxSx = { ...styles.OuterBoxSx, ...outerBoxOverrideSx };

  // data-testids
  const outerBoxTestId =
    props.outerBoxTestId ||
    `${filterSubTree}-complex-filter-drawer-outer-box-test-id`;

  return (
    <Box sx={OuterBoxSx} data-testid={outerBoxTestId} data-open={popoverOpen}>
      <Box ref={refEl}>
        <FilterToggleButton
          buttonCopy={props.buttonCopy || 'Filters'}
          filterSubTree={props.filterSubTree}
          selectAllChosenEntries={props.selectAllChosenEntries}
          selectIsFiltered={props.selectIsFiltered}
          onToggleFilterButton={props.onToggleFilterButton}
          selectPopoverOpen={props.selectPopoverOpen}
          selectFilterBadgeSx={selectFilterBadgeSx}
          selectFilterBadgeTestId={selectFilterBadgeTestId}
          FilterButtonOverrideSx={filterButtonOverrideSx}
        />
      </Box>
      <Popover
        open={popoverOpen}
        anchorEl={refEl.current || null}
        onClose={props.onClosePopover}
        keepMounted={true}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={styles.FilterPopoverSx}
      >
        <FilterDrawerHeader
          filterSubTree={filterSubTree}
          onClosePopover={props.onClosePopover}
        />
        <FilterDrawerContent
          {...props}
          filterSubTree={filterSubTree}
          blueprint={props.filterModalConfig.blueprint}
          typeAheadHandlersConfig={props.filterModalConfig.typeAheadHandlers}
          dropDownHandlersConfig={props.filterModalConfig.dropdownHandlers}
          fullTextHandlersConfig={props.filterModalConfig.fullTextHandlers}
          checkboxHandlersConfig={props.filterModalConfig.checkboxHandlers}
        />
        <FilterDrawerFooter
          selectIsFiltered={props.selectIsFiltered}
          filterSubTree={filterSubTree}
          onClearAllFilters={props.onSoftClearAllFilters}
        />
      </Popover>
    </Box>
  );
};

export default ComplexFilterDrawer;
