import React from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { useQuery, useApolloClient } from '@apollo/client';
import {
  FilterColumnsArgs,
  GetColumnForNewFilterArgs,
  GridCallbackDetails,
  GridFilterModel,
  GridLogicOperator,
  GridPaginationModel,
  GridRowSelectionModel,
  GridToolbar,
} from '@mui/x-data-grid-pro';
import { uniq } from 'lodash';
// WARNING: DO NOT just REMOVE the TEST ID
import {
  TEST_ID_STEP_COMPONENT_EMPLOYEE,
  RetrievalAssetTypes,
} from 'pages/Retrieval/constants';
import useGetUserOrganization from 'hooks/useGetUserOrganization';
import DataGrid from 'components/DataGrid';
import { EmployeeFilterColumns } from './constants';
import { EmployeeStepProps } from './types';
import {
  RETRIEVE_COLLABORATOR_ADDRESSES,
  SEARCH_COLLABORATORS_BY_ORG_ID,
} from './queries';
import { EmployeeStepMainContainerSx } from './styles';
import { FETCH_RETRIEVAL_OPTIONS } from 'pages/Retrieval/components/ReviewStep/components/RecipientAssetDetails/queries';
import { RetrievalOptionAnswer } from 'pages/Retrieval/RetrievalService/types';
import { getDefaultAnswers } from 'pages/Retrieval/components/ReviewStep/utils';
import { RetrievalContext } from 'context/RetrievalServiceProvider';
import { COLLABORATORS_PAGE_LIMIT } from 'global-constants';
import { removeDuplicateFilters } from 'components/DataGrid/utils';
import {
  ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
  HIDE_SELECT_ALL_CHECKBOX_CLASS_NAME,
} from 'components/DataGrid/constants';
import { Asset, Collaborator, HomeAddress, WorkAddress } from 'types';
import { getEmployeeStepColumns } from './utils';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import { useSafeLazyQuery } from 'hooks/useSafeLazyQuery';
import * as Sentry from '@sentry/react';

const EmployeeStep = (props: EmployeeStepProps) => {
  const [firstNameFilter, setFirstNameFilter] = React.useState<string>('');
  const [lastNameFilter, setLastNameFilter] = React.useState<string>('');
  const [emailFilter, setEmailFilter] = React.useState<string>('');

  const [paginationModel, setPaginationModel] =
    React.useState<GridPaginationModel>({
      page: 0,
      pageSize: COLLABORATORS_PAGE_LIMIT,
    });

  const [rowSelectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>(() =>
      props.retrievalFlow.selectedEmployees.map(({ id }) => id)
    );

  const [assetTypeOptions, setAssetTypeOptions] = React.useState<
    Record<string, any>
  >({});

  const userOrganization = useGetUserOrganization();

  const variables = {
    organizationId: userOrganization?.id,
    offset: paginationModel.page * COLLABORATORS_PAGE_LIMIT,
    limit: COLLABORATORS_PAGE_LIMIT,
    [EmployeeFilterColumns.FIRST_NAME]: firstNameFilter,
    [EmployeeFilterColumns.LAST_NAME]: lastNameFilter,
    [EmployeeFilterColumns.EMAIL]: emailFilter,
  };
  const { data, loading } = useQuery(SEARCH_COLLABORATORS_BY_ORG_ID, {
    variables,
    fetchPolicy: 'network-only',
  });

  const [retrieveCollaboratorAddresses] = useSafeLazyQuery(
    RETRIEVE_COLLABORATOR_ADDRESSES,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const availableAddresses = [
          ...(data?.collaborator?.homeAddresses?.map(
            (address: HomeAddress) => ({
              ...address,
              isHome: true,
              isWork: false,
            })
          ) || []),
          ...(data?.collaborator?.workAddresses?.map(
            (address: WorkAddress) => ({
              ...address,
              isHome: false,
              isWork: true,
            })
          ) || []),
        ];
        availableAddresses.sort((a, b) =>
          b.id === data?.collaborator?.primaryAddress?.id ? 1 : -1
        );
        if (availableAddresses.length === 0) return;
        props.retrievalFlow.addHomeAndWorkAddressesToEmployee(
          data?.collaborator?.id,
          data?.collaborator?.homeAddresses || [],
          data?.collaborator?.workAddresses || []
        );
        props.retrievalFlow.addShippingAddressOptionsToEmployee(
          data?.collaborator?.id,
          availableAddresses
        );
      },
    }
  );

  const apolloClient = useApolloClient();
  const assetTypes = Object.values(RetrievalAssetTypes);
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('lg')
  );

  const EMPLOYEE_COLUMNS = getEmployeeStepColumns(isLargeScreen);

  const getAssetOptions = async () => {
    const promises = assetTypes.map(async (assetName) => {
      const variables = {
        assetTypeName:
          RetrievalAssetTypes[assetName as keyof typeof RetrievalAssetTypes],
      };

      const { data } = await apolloClient.query({
        query: FETCH_RETRIEVAL_OPTIONS,
        variables,
      });

      return data.retrievalOptions;
    });

    const allOptionValuesData = await Promise.all(promises);

    setAssetTypeOptions(() => {
      return allOptionValuesData.reduce((acc, next, index) => {
        acc[assetTypes[index]] = next;
        return acc;
      }, {} as any);
    });
  };

  React.useEffect(() => {
    getAssetOptions();

    return () => setAssetTypeOptions({});
  }, []);

  const assetOptionEntries = Object.entries(assetTypeOptions);
  const hasAssetOptions = !!assetOptionEntries.length;

  React.useEffect(() => {
    if (!Object.keys(assetTypeOptions).length) return;
    props.retrievalFlow.initOptionValuesByType(assetTypeOptions);
  }, [assetTypeOptions]);

  const defaultAssetOptionAnswers: Record<string, RetrievalOptionAnswer[]> =
    React.useMemo(() => {
      if (!hasAssetOptions) return {};
      return getDefaultAnswers(assetTypeOptions);
    }, [hasAssetOptions]);

  const hasDefaultAnswers =
    Object.entries(defaultAssetOptionAnswers).length > 0;

  React.useEffect(() => {
    if (!hasDefaultAnswers) return;
    props.retrievalFlow.initAssetsOptionAnswerKey(defaultAssetOptionAnswers);
  }, [hasDefaultAnswers, defaultAssetOptionAnswers]);

  // TODO Cleanup this logic post ERP
  const collaboratorsData =
    data?.collaborators?.collaborators.map((collaborator: Collaborator) => {
      // @ts-ignore
      const newAssets = collaborator.assets.map((asset: Asset) => {
        return {
          ...asset,
          type:
            typeof asset.type !== 'string'
              ? // @ts-ignore
                asset.type.name.toUpperCase()
              : asset.type,
          dateIssued:
            asset?.inventoryLog?.dateOfLastCheckOut ?? asset.dateOfLastCheckOut,
        };
      });
      return {
        ...collaborator,
        assets: newAssets,
      };
    }) || [];

  const collaboratorsRowCount = data?.collaborators?.count || 0;

  const onRowSelectionChange = (ids: GridRowSelectionModel) => {
    const selectedEmployees = collaboratorsData
      .slice()
      .filter((employee: any) => ids.includes(employee.id));

    const selectedIds = props.retrievalFlow.selectedEmployees.map(
      ({ id }) => id
    );
    const incomingIds = ids as string[];
    const addedIds = incomingIds
      .slice()
      .filter((id) => !selectedIds.includes(id));

    const addingEmployee = Boolean(addedIds.length);
    const removingEmployee = !addingEmployee;

    if (addingEmployee) {
      addedIds.forEach((id: string) => {
        retrieveCollaboratorAddresses({
          variables: { id },
        });
      });
      props.retrievalFlow.selectEmployees(selectedEmployees);
    }

    if (removingEmployee) {
      const removedEmployeeId =
        selectedIds.find((selectId) => !ids.includes(selectId)) ?? '';

      props.retrievalFlow.removeEmployee(removedEmployeeId);
    }

    setSelectionModel(ids);
  };

  const resetAllFilters = () => {
    setFirstNameFilter('');
    setLastNameFilter('');
    setEmailFilter('');
  };

  const resetCertainFilters = (model: GridFilterModel) => {
    const itemsFilteredFields = model.items.map((item) => item.field);
    if (!itemsFilteredFields.includes(EmployeeFilterColumns.FIRST_NAME)) {
      setFirstNameFilter('');
    }
    if (!itemsFilteredFields.includes(EmployeeFilterColumns.LAST_NAME)) {
      setLastNameFilter('');
    }
    if (!itemsFilteredFields.includes(EmployeeFilterColumns.EMAIL)) {
      setEmailFilter('');
    }
  };

  const handleFilterChange = (
    model: GridFilterModel,
    details: GridCallbackDetails<'filter'>
  ) => {
    removeDuplicateFilters(model);
    const { reason } = details;
    if (reason === 'deleteFilterItem' || reason === undefined) {
      if (model.items.length === 0) {
        resetAllFilters();
      } else {
        resetCertainFilters(model);
      }
    } else if (reason === 'upsertFilterItem') {
      model.items.forEach((item) => {
        if (item.field === EmployeeFilterColumns.FIRST_NAME) {
          setFirstNameFilter(item.value);
        } else if (item.field === EmployeeFilterColumns.LAST_NAME) {
          setLastNameFilter(item.value);
        } else if (item.field === EmployeeFilterColumns.EMAIL) {
          setEmailFilter(item.value);
        }
      });
    }
  };

  const handleGetColumnForNewFilter = (args: GetColumnForNewFilterArgs) => {
    const availableFilters = [
      EmployeeFilterColumns.LAST_NAME,
      EmployeeFilterColumns.FIRST_NAME,
      EmployeeFilterColumns.EMAIL,
    ];
    const currentFilters = args.currentFilters.map(
      (currentFilter) => currentFilter.field
    );
    const uniqueFilters = availableFilters.filter(
      (filter) => !currentFilters.includes(filter)
    );
    return uniqueFilters[0] ?? undefined;
  };

  return (
    <>
      <Container
        data-testid={TEST_ID_STEP_COMPONENT_EMPLOYEE}
        sx={EmployeeStepMainContainerSx}
      >
        <Stack marginTop="4rem">
          <DataGrid
            pagination
            checkboxSelection
            enableDynamicRowHeight
            filterMode="server"
            paginationMode="server"
            rows={collaboratorsData}
            rowCount={collaboratorsRowCount}
            columns={EMPLOYEE_COLUMNS}
            loading={loading}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={onRowSelectionChange}
            onFilterModelChange={handleFilterChange}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            slotProps={{
              filterPanel: {
                logicOperators: [GridLogicOperator.And],
                getColumnForNewFilter: handleGetColumnForNewFilter,
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            classes={{
              virtualScroller: ENABLE_HORIZONTAL_SCROLLING_CLASS_NAME,
              columnHeaderCheckbox: HIDE_SELECT_ALL_CHECKBOX_CLASS_NAME,
            }}
          />
        </Stack>
      </Container>
    </>
  );
};

export const EmployeeStepWrapped = () => {
  const retrievalFlow = React.useContext(RetrievalContext);

  return <EmployeeStep retrievalFlow={retrievalFlow} />;
};

export default EmployeeStep;
