/* eslint-disable sort-exports/sort-exports */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { OrderStatusMapping } from 'globals/constants';
import { ORDER_STATUS_FILTER_SETTINGS } from './constants';
import * as OrderStatusFilterStyles from './styles';
import {
  clearSearchFilterByKey,
  setSearchFilterByKey,
} from 'store/slices/orders/search';
import { selectOrderSearchByKey } from 'store/slices/orders/search/selectors';

const OrderStatusFilter = () => {
  const styles = OrderStatusFilterStyles;
  const SEARCH_KEY = 'statusFilter';
  const dispatch = useDispatch();
  const [orderStatusValueLookup, setOrderStatusValueLookup] = React.useState<
    Record<string, any> | undefined
  >();
  const [orderStatusOptions, setOrderStatusOptions] = React.useState<string[]>(
    [],
  );
  const selectedOrderStatus = useSelector(selectOrderSearchByKey(SEARCH_KEY));

  const setupOrderStatusOptions = () => {
    const orderStatusLookup = Object.keys(OrderStatusMapping).reduce(
      (acc, stringKey: string) => {
        const value =
          OrderStatusMapping[stringKey as keyof typeof OrderStatusMapping];
        acc[value] = stringKey;
        return acc;
      },
      {} as Record<string, any>,
    );

    setOrderStatusValueLookup(orderStatusLookup);
    setOrderStatusOptions(() => ['', ...Object.keys(orderStatusLookup)]);
  };

  const handleClose = () => {
    dispatch(clearSearchFilterByKey(SEARCH_KEY));
  };

  const handleOrderStatusChange = (event: SelectChangeEvent) => {
    const { value: newChosenOrderStatus } = event.target;

    if (!newChosenOrderStatus || newChosenOrderStatus === selectedOrderStatus)
      return;
    dispatch(
      setSearchFilterByKey({
        key: SEARCH_KEY,
        value: newChosenOrderStatus,
      }),
    );
  };

  React.useEffect(() => {
    setupOrderStatusOptions();
  }, []);

  if (!orderStatusOptions.length || !orderStatusValueLookup) return null;

  return (
    <Stack sx={styles.FilterStackSx}>
      {selectedOrderStatus ? (
        <Tooltip title="Clear" arrow>
          <IconButton
            size="small"
            aria-label="clear order type filter"
            onClick={handleClose}
            sx={styles.FilterClearButtonSx}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      ) : null}

      <FormControl
        sx={styles.getFilterFormControlSx(ORDER_STATUS_FILTER_SETTINGS.labelId)}
      >
        <InputLabel id={ORDER_STATUS_FILTER_SETTINGS.labelId}>
          {ORDER_STATUS_FILTER_SETTINGS.label}
        </InputLabel>
        <Select
          value={selectedOrderStatus}
          onChange={handleOrderStatusChange}
          sx={styles.FilterSelectSx}
          placeholder={ORDER_STATUS_FILTER_SETTINGS.placeholder}
          label={ORDER_STATUS_FILTER_SETTINGS.label}
          labelId={ORDER_STATUS_FILTER_SETTINGS.labelId}
          id={ORDER_STATUS_FILTER_SETTINGS.inputId}
        >
          {orderStatusOptions.map((assetTypeOption: string) => (
            <MenuItem
              key={assetTypeOption}
              value={
                orderStatusValueLookup[
                  assetTypeOption as keyof typeof orderStatusValueLookup
                ]
              }
            >
              {assetTypeOption}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default OrderStatusFilter;
