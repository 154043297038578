import { ORDER_STATUS_DROPDOWN_OPTIONS } from 'globals/constants';
import {
  getDropDownEntries,
  upsertDummyOptionsHOF,
} from 'components/complex_filter/utils';
import { getDepotsOnCompletedHOF } from 'pages/Assets/filters/utils';
import {
  FilterLayoutSection,
  FilterModalConfig,
  FilterTestIds,
  BaseFilterBankConfig,
  DropDownOption,
  TypeAheadSxOverwrites,
} from 'components/complex_filter/types';
import { orderManagementFilterActions } from 'store/slices/order_management/filter';
import { FILTER_FIELD_TYPES } from 'store/constants';
import {
  OMDropdownConfig,
  OMTypeaheadConfig,
  OMFullTextConfig,
  OMCheckboxConfig,
} from 'store/slices/order_management/filter/types';
import {
  getDummyNode,
  getOrderNumberOptions,
  getAllOrderTypes,
  getOMOrganizationOptions,
  getPurchaserOptions,
  getRecipientEmailOptions,
  getOrderManagerOptions,
  getShippingSelectionTypes,
  getFirst100Depots,
  getTrackingNumberOption,
} from 'api/apollo/queries/v1';
import {
  getOrderTypesOnCompletedHOF,
  getShippingTypesOnCompletedHOF,
  getPurchaserTypeaheadOnCompletedHOF,
  getOrderNumberTypeaheadOnCompletedHOF,
  getTrackingNoOnCompletedHOF,
  getOrgsTypeaheadOnCompletedHOF,
  getOrderManagersOnCompletedHOF,
  getGenericQueryVariablesHOF,
  getOrderNumberQueryVariablesHOF,
  getOMOrganizationQueryVariablesHOF,
  getTrackingNoHOF,
} from 'pages/OrderManagement/filters/shared/utils';

export const OMDashFilterKeys = {
  ORDER_NUMBER: 'order-number',
  ORDER_TYPE: 'order-type',
  ORDER_DATE: 'order-date',
  DUE_DATE: 'due-date',
  STATUS: 'order-status',
  DEPOT: 'depot',
  SHIPPING_SELECTION: 'shipping-selection',
  TRACKING_NUMBER: 'tracking-number',
  ORGANIZATION: 'organization',
  ORDER_MANAGER: 'order-manager',
  CUSTOMER_NOTES: 'customer-notes',
  PURCHASER: 'purchaser',
  PURCHASER_EMAIL: 'purchaser-email',
  RECIPIENT: 'recipient',
  RECIPIENT_EMAIL: 'recipient-email',
};

export const OM_DASH_FILTER_BANK_CONFIG: BaseFilterBankConfig = {
  actions: orderManagementFilterActions,
  componentTestId: 'orders-mgmt-dash-filter-bank-outer',
  bankTestId: 'orders-mgmt-dash-filter-bank-inner',
};

export const OM_DASH_FILTER_LAYOUT: FilterLayoutSection[] = [
  {
    section: 'Allwhere Info',
    layout: [
      {
        filterKey: OMDashFilterKeys.ORDER_NUMBER,
        placeholder: 'Order number',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.ORDER_TYPE,
        placeholder: 'Order type',
        fieldType: FILTER_FIELD_TYPES.DROPDOWN,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.ORDER_DATE,
        placeholder: 'Order date',
        fieldType: FILTER_FIELD_TYPES.DATETIME,
        span: 'full',
      },
      {
        filterKey: OMDashFilterKeys.DUE_DATE,
        placeholder: 'Due date',
        fieldType: FILTER_FIELD_TYPES.DATETIME,
        span: 'full',
      },
      {
        filterKey: OMDashFilterKeys.STATUS,
        placeholder: 'Status',
        fieldType: FILTER_FIELD_TYPES.DROPDOWN,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.DEPOT,
        placeholder: 'Depot',
        fieldType: FILTER_FIELD_TYPES.DROPDOWN,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.SHIPPING_SELECTION,
        placeholder: 'Shipping selection',
        fieldType: FILTER_FIELD_TYPES.DROPDOWN,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.TRACKING_NUMBER,
        placeholder: 'Tracking number',
        fieldType: FILTER_FIELD_TYPES.FULL_TEXT,
        span: 'half',
        inputCTA: 'Enter tracking number',
      },
      {
        filterKey: OMDashFilterKeys.ORGANIZATION,
        placeholder: 'Organization',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.ORDER_MANAGER,
        placeholder: 'Order manager',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
    ],
  },
  {
    section: 'Customer Notes',
    layout: [
      {
        filterKey: OMDashFilterKeys.CUSTOMER_NOTES,
        placeholder: 'Customer notes',
        fieldType: FILTER_FIELD_TYPES.CHECKBOX,
        span: 'full',
      },
    ],
  },
  {
    section: 'Purchaser Info',
    layout: [
      {
        filterKey: OMDashFilterKeys.PURCHASER,
        placeholder: 'Purchaser',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.PURCHASER_EMAIL,
        placeholder: 'Purchaser email',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
    ],
  },
  {
    section: 'Recipient Info',
    layout: [
      {
        filterKey: OMDashFilterKeys.RECIPIENT,
        placeholder: 'Recipient',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
      {
        filterKey: OMDashFilterKeys.RECIPIENT_EMAIL,
        placeholder: 'Recipient email',
        fieldType: FILTER_FIELD_TYPES.TYPEAHEAD,
        span: 'half',
      },
    ],
  },
];

export const OM_DASH_FILTER_TEST_IDS: FilterTestIds = {
  outerBoxTestId: 'order-mgmt-filter-drawer-outer-box',
  filterButtonTestId: 'order-mgmt-filter-drawer-toggle-btn',
  selectFilterBadgeTestId: 'order-mgmt-filter-counter-badge',
};

export const GET_ORDER_NUMBER_OPTIONS_OUTPUT = `
  orders {
    id
    orderNumber
  }
`;

export const GET_ORGANIZATION_OPTIONS_OUTPUT = `
  organizations {
    id
    name
  }
  count
`;

export const GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT = `
  field
  fields
  matches
  matchIds
  count
  entity
`;

const GET_ALL_ORDER_TYPES_OUTPUT = `
  id
  name
`;

const GET_SHIPPING_SELECTION_TYPES_OUTPUT = `
  shippingTypes {
    id
    name
  }
`;

export const GET_FIRST_100_DEPOTS_OUTPUT = `
  depots {
    id
    name
  }
`;

export const GET_SEARCH_OM_ORDERS_OUTPUT = `
orders {
  id
  rush
  orderNumber
  createdAt
  depotId
  status
  internalNotes
  checkoutNotes
  sendOrderNotifications
  dueDate
  shippingTypeId
  orderType {
    id
    name
  }
  orderManager {
    id
    email
    firstName
    lastName
  }
  recipientId
  recipient {
    id
    firstName
    lastName
    email
    collaboratorId
  }
  dropRecipient {
    id
    firstName
    lastName
    email
    collaboratorId
  }
  dropRecipientId
  organizationObject {
    id
    name
  }
  purchaser {
    id
    firstName
    lastName
    email
  }
  depot {
    id
    name
  }
}
count
`;

export const V2_FIND_AND_COUNT_ORDERS_OUTPUT = `
  orders {
  id
  rush
  orderNumber
  createdAt
  depotId
  status
  internalNotes
  checkoutNotes
  sendOrderNotifications
  dueDate
  finalDestination
  shippingTypeId
  recipientId
  dropRecipientId
    orderType {
      id
      name
    }
    organization {
      id
      name
    }
    recipient {
      id
      firstName
      lastName
      email
      collaboratorId
    }
    dropRecipient {
      id
      firstName
      lastName
      email
      collaboratorId
    }
    orderManager {
      id
      email
      firstName
      lastName
    }
    orderItems {
      id
    }
    purchaser {
      id
      firstName
      lastName
    }
    depot {
      id
      name
    }
    lastOrderStatusTransition {
      id
      orderId
      from
      to
      createdAt
    }
  }
  count
`;

export const GET_TRACKING_OPTION_OUTPUT = `
  count
  trackings {
    id
    trackingNumber
  }
`;

export const GET_ALL_DEPOTS_OUTPUT = `
  id
  name
`;

export const OM_DASH_CHECKBOX_HANDLERS: OMCheckboxConfig = {
  'customer-notes': {
    optionLabel: 'Yes',
  },
};

export const OM_DASH_FULL_TEXT_HANDLERS: OMFullTextConfig = {
  'tracking-number': {
    lookupQuery: getTrackingNumberOption(GET_TRACKING_OPTION_OUTPUT),
    onCompletedHOF: getTrackingNoOnCompletedHOF,
    counterAvatarRightOffset: 45,
    getQueryVariables: getTrackingNoHOF(),
    notFoundFallback: 'Tracking number not found',
  },
};

const RECIPIENT_SX_OVERWRITES: TypeAheadSxOverwrites = {
  baseSx: {
    '& .MuiFormControl-root .MuiFormLabel-root.MuiInputLabel-root .MuiInputLabel-shrink': { zIndex: 1301 },
  },
  popperSx: {
    border: '10px solid transparent',
    borderLeft: 'none',
    borderRight: 'none',
  },
}

export const OM_DASH_TYPEAHEAD_HANDLERS: OMTypeaheadConfig = {
  'order-number': {
    lookupQuery: getOrderNumberOptions(GET_ORDER_NUMBER_OPTIONS_OUTPUT),
    onCompletedHOF: getOrderNumberTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 55,
    getQueryVariables: getOrderNumberQueryVariablesHOF({}),
  },
  organization: {
    lookupQuery: getOMOrganizationOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getOrgsTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 65,
    getQueryVariables: getOMOrganizationQueryVariablesHOF({ limit: 15 })
  },
  purchaser: {
    lookupQuery: getPurchaserOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 88,
    getQueryVariables: getGenericQueryVariablesHOF({}),
  },
  'purchaser-email': {
    lookupQuery: getRecipientEmailOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 45,
    getQueryVariables: getGenericQueryVariablesHOF({}),
  },
  recipient: {
    lookupQuery: getPurchaserOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 92,
    getQueryVariables: getGenericQueryVariablesHOF({}),
    sxOverwrites: RECIPIENT_SX_OVERWRITES,
  },
  'recipient-email': {
    lookupQuery: getRecipientEmailOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getPurchaserTypeaheadOnCompletedHOF,
    counterAvatarRightOffset: 50,
    getQueryVariables: getGenericQueryVariablesHOF({}),
    sxOverwrites: RECIPIENT_SX_OVERWRITES,
  },
  'order-manager': {
    lookupQuery: getOrderManagerOptions(GET_AUTOCOMPLETE_SUGGESTIONS_OUTPUT),
    onCompletedHOF: getOrderManagersOnCompletedHOF,
    counterAvatarRightOffset: 48,
    getQueryVariables: getGenericQueryVariablesHOF({ searchField: 'firstOrLastName' }),
  },
};

export const OM_DASH_DROPDOWN_HANDLERS: OMDropdownConfig = {
  'order-type': {
    asyncOptionsQuery: getAllOrderTypes(GET_ALL_ORDER_TYPES_OUTPUT),
    counterOffsetRight: 85,
    inputLabel: 'Order type',
    makeSelectionText: 'Select Order type',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getOrderTypesOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  'order-status': {
    asyncOptionsQuery: getDummyNode('count'),
    counterOffsetRight: 115,
    inputLabel: 'Status',
    makeSelectionText: 'Select status',
    optionsType: 'hard-coded',
    presetOptions: ORDER_STATUS_DROPDOWN_OPTIONS,
    upsertOptionsHOF: upsertDummyOptionsHOF,
    getPreloadedEntries: getDropDownEntries,
  },
  depot: {
    asyncOptionsQuery: getFirst100Depots(GET_FIRST_100_DEPOTS_OUTPUT),
    counterOffsetRight: 51,
    inputLabel: 'Depot Location',
    makeSelectionText: 'Select Location',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getDepotsOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
  'shipping-selection': {
    asyncOptionsQuery: getShippingSelectionTypes(GET_SHIPPING_SELECTION_TYPES_OUTPUT),
    counterOffsetRight: 28,
    inputLabel: 'Shipping selection',
    makeSelectionText: 'Select shipping',
    optionsType: 'async',
    presetOptions: [],
    upsertOptionsHOF: getShippingTypesOnCompletedHOF,
    getPreloadedEntries: (key: string, options: DropDownOption[]) => [],
  },
};

export const OM_DASH_FILTER_MODAL_CONFIG: FilterModalConfig = {
  blueprint: OM_DASH_FILTER_LAYOUT,
  typeAheadHandlers: OM_DASH_TYPEAHEAD_HANDLERS,
  dropdownHandlers: OM_DASH_DROPDOWN_HANDLERS,
  fullTextHandlers: OM_DASH_FULL_TEXT_HANDLERS,
  checkboxHandlers: OM_DASH_CHECKBOX_HANDLERS,
  actions: orderManagementFilterActions,
};
